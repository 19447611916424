
import { defineComponent, ref, computed, reactive, watch } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import Catalog from './components/Catalog.vue'
import Agencies from './components/Agencies.vue'
import { IBaseCost } from '@/interfaces/base-cost'
import { useRoute, useRouter } from 'vue-router'
import { useNotification } from '@/composables'
import useBaseCostReponsitories from '@/repositories/useBaseCostReponsitories'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Catalog,
    Agencies,
  },
  setup(props) {
    const breadcrumbs = ['Base Cost', 'Create']
    const { error, success } = useNotification()
    const content = computed(() => {
      return { breadcrumbs }
    })

    const route = useRoute()
    const router = useRouter()
    const formBaseCost = ref()
    const agencyComponent = ref()
    const catalogComponent = ref()

    const state = reactive({
      dialogVisible: false,
      isLoadingAction: false,
      ruleForm: {
        name: '',
        default: true,
        status: true,
      } as IBaseCost,
      rules: {
        name: [
          {
            required: true,
            message: 'Please input name',
            trigger: 'blur',
          },
        ],
      },
      groups: ref<any[]>([]),
      columns: ref([
        {
          title: 'Created',
          dataIndex: 'created',
        },
        {
          title: 'Name',
          dataIndex: 'created',
        },
        {
          title: 'Code',
          dataIndex: 'created',
        },
        {
          title: 'Balance',
          dataIndex: 'created',
        },
      ]),
      tableData: ref<any[]>([]),
      showModalAddSeller: ref<boolean>(false),
    })

    const { addBaseCost } = useBaseCostReponsitories()

    const onSave = async () => {
      formBaseCost.value.validate(async (valid: any) => {
        if (valid) {
          state.isLoadingAction = true
          let addedAgencies = [...agencyComponent.value.state.addedAgencies]
          let addedAgenciesId = addedAgencies.map((s: any) => s.id)

          let deletedAgencies = [...agencyComponent.value.state.deletedAgencies]
          let deletedAgenciesId = deletedAgencies.map((s: any) => s.id)

          let addedCatalogs = [...catalogComponent.value.state.addedCatalogs]
          let addedCatalogsIds = addedCatalogs.map((s: any) => s._id)

          let deletedCatalogs = [
            ...catalogComponent.value.state.deletedCatalogs,
          ]
          let deletedCatalogsIds = deletedCatalogs.map((s: any) => s._id)

          let params = {
            ...state.ruleForm,
            agencies: {
              add: addedAgenciesId,
              delete: deletedAgenciesId,
            },
            catalogs: {
              add: addedCatalogsIds,
              delete: deletedCatalogsIds,
            },
          }
          let res = await addBaseCost(params as IBaseCost)
          state.isLoadingAction = false
          if (res.status === 201 || res.status === 200) {
            success('Add basecost success')
            router.push({
              name: 'admin.baseCost.update',
              params: { id: res.data.id },
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }

    const onSaveAndClose = () => {
      formBaseCost.value.validate(async (valid: any) => {
        if (valid) {
          state.isLoadingAction = true

          let addedAgencies = [...agencyComponent.value.state.addedAgencies]
          let addedAgenciesId = addedAgencies.map((s: any) => s.id)

          let deletedAgencies = [...agencyComponent.value.state.deletedAgencies]
          let deletedAgenciesId = deletedAgencies.map((s: any) => s.id)

          let addedCatalogs = [...catalogComponent.value.state.addedCatalogs]
          let addedCatalogsIds = addedCatalogs.map((s: any) => s._id)

          let deletedCatalogs = [
            ...catalogComponent.value.state.deletedCatalogs,
          ]
          let deletedCatalogsIds = deletedCatalogs.map((s: any) => s._id)

          let params = {
            ...state.ruleForm,
            sellerTeams: {
              add: addedAgenciesId,
              delete: deletedAgenciesId,
            },
            catalogs: {
              add: addedCatalogsIds,
              delete: deletedCatalogsIds,
            },
            group: route.params.groupId,
          }
          let res = await addBaseCost(params as IBaseCost)
          console.log('restdata-->', res)
          if (res.status === 201 || res.status === 200) {
            success('Add basecost success')
            router.push({
              name: 'admin.baseCost',
            })
          }
          state.isLoadingAction = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }

    return {
      content,
      state,
      formBaseCost,
      agencyComponent,
      catalogComponent,
      onSave,
      onSaveAndClose,
    }
  },
})
